<template>
	<div>
	<!--<div class="row">
      <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-4">
        <ListWidget9></ListWidget9>
      </div>
      <div class="col-xxl-4">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-1">
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-xxl-8 order-2 order-xxl-1">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <StatsWidget13></StatsWidget13>
      </div>
      <div class="col-xxl-8 order-1 order-xxl-2">
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div>{{$t('ORDER.MSG_DESC_COMPTE_OK')}}
    </div>-->
		<div class="subheader row">
			<div class="col-xl-6 col-md-12 col-xs-12 col-sm-12">
			</div>
			<div class="col-xl-6 col-md-12 col-xs-12 col-sm-12" style="padding: 0;">
				<div class="d-flex align-items-md-center flex-column flex-md-row justify-content-end">
					<div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
						<div class="form d-flex align-items-md-center flex-sm-row flex-column flex-grow-1 flex-sm-grow-0">
							<div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
								<datetime type="date" :placeholder="$t('ORDER.MSG_DATE_START')" v-model="dateStart" input-class="form-control border-0 font-weight-bold pl-2" :phrases="{ok: $t('ORDER.MSG_UPDATE'), cancel: $t('ORDER.MSG_CANCEL')}" :week-start="7" auto></datetime>
							</div>
							<span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
							<div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
								<datetime type="date" :placeholder="$t('ORDER.MSG_DATE_END')" v-model="dateEnd" input-class="form-control border-0 font-weight-bold pl-2" :phrases="{ok: $t('ORDER.MSG_UPDATE'), cancel: $t('ORDER.MSG_CANCEL')}" :week-start="7" auto></datetime>
							</div>
							<span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
							<button type="submit" class="btn btn-dark font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="GetOrder()"> {{$t('ORDER.MSG_OK')}} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" >
			<div class="col-12 p-4">
				<div class="card-spacer bg-white card-rounded flex-grow-1">
					<div class="row m-0">
						<div class="col px-8 py-6 mr-8" v-for="(value, name) in OrderStatus" :key="name" :value="name" :style="{color:value.color}">
							<div class="font-size-sm text-muted font-weight-bold"> {{value.text}} </div>
							<div class="font-size-h4 font-weight-bolder" >{{CalculOrderStatus[name] ? CalculOrderStatus[name] : 0}}</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-12 col-12" id="kt_profile_aside">
				<div class="card card-custom card-stretch">
					<div class="card-body">
						<template>
							<b-container fluid>
							<!-- User Interface controls -->
								<b-row>
								<b-col lg="6" class="my-1">
									<b-form-group
									  label="Rechercher"
									  label-for="filter-input"
									  label-cols-sm="3"
									  label-align-sm="right"
									  label-size="sm"
									  class="mb-0"
									>
									<b-input-group size="sm">
										<b-form-input
										  id="filter-input"
										  v-model="filter"
										  type="search"
										  :placeholder="$t('ORDER.MSG_FIND_DATA')"
										></b-form-input>

										<b-input-group-append>
										  <b-button :disabled="!filter" @click="filter = ''">{{$t('ORDER.MSG_DELETE')}}</b-button>
										</b-input-group-append>
									</b-input-group>
									</b-form-group>
								</b-col>

							  

							  <b-col sm="5" md="6" class="my-1">
								<b-form-group
								  :label="$t('ORDER.MSG_PER_PAGE')"
								  label-for="per-page-select"
								  label-cols-sm="6"
								  label-cols-md="4"
								  label-cols-lg="3"
								  label-align-sm="right"
								  label-size="sm"
								  class="mb-0"
								>
								  <b-form-select
									id="per-page-select"
									v-model="perPage"
									:options="pageOptions"
									size="sm"
								  ></b-form-select>
								</b-form-group>
							  </b-col>

							  
							</b-row>

							<!-- Main table element -->
							<b-table
								striped hover
							  :items="items"
							  :fields="fields"
							  :current-page="currentPage"
							  :per-page="perPage"
							  :filter="filter"
							  :filter-included-fields="filterOn"
							  :sort-by.sync="sortBy"
							  :sort-desc.sync="sortDesc"
							  :sort-direction="sortDirection"
							  stacked="md"
							  show-empty
							  small
							  @filtered="onFiltered"
							  :empty-text="$t('ORDER.MSG_EMPTY_DATA')"
								:empty-filtered-text="$t('ORDER.MSG_EMPTY_DATA')"
							>
							  <template #cell(type)="row">
								<v-icon large left v-if="row.item.type != '' && row.item.type != 'null' && row.item.type != null" style="font-size:20px">
									{{ModePassOrder[row.item.type].icon}}
								</v-icon>
								<img src="media/klik/logo-min.png" style="width:20px" v-else>
							  </template>
							  <template #cell(methode)="row">
								<b class="badgeStatus" :style="{background:ModeDelivery[row.item.methode].color}" v-if="row.item.methode != '' && row.item.methode != null && row.item.methode != 'null'">{{ModeDelivery[row.item.methode].text}}</b>
								
							  </template>
							  <template #cell(date_order)="row">
								{{ moment(parseFloat(row.item.date_order)).format("DD/MM/YYYY HH:mm:ss")}}
							  </template>
							  <template #cell(adresse)="row">
								<a v-if="row.item.adresse_url && row.item.adresse_url != ''" :href="row.item.adresse_url" target="_blank"><i style="color:#000" class="la la-map-marked-alt"></i>&nbsp;&nbsp;{{row.item.adresse}}</a>
								<div v-if="row.item.lat_lng && row.item.lat_lng != '' && row.item.lat_lng != null &&  typeof row.item.lat_lng === 'object' " style="width:100px">
									<a :href="'https://www.google.com/maps/search/?api=1&query=' + JSON.parse(row.item.lat_lng).lat + ',' + JSON.parse(row.item.lat_lng).lng" target="_blank"><i style="color:#000" class="la la-map-marked-alt"></i>&nbsp;&nbsp;{{row.item.adresse}}</a>
								</div>
							  </template>
							  <template #cell(status)="row" >
								<b class="badgeStatus" :style="{background:OrderStatus[row.item.status.toUpperCase()].color}" v-if="row.item.status != '' && row.item.status != null && row.item.status != 'null'">{{OrderStatus[row.item.status.toUpperCase()].text}}</b>
							  </template>
							  <template #cell(price_total)="row">
								{{ CurrencyFormat(row.item.price_total) }}
							  </template>
								 <template #row-details="row">
								<b-card>
								  <ul>
									<li v-for="(value, key) in row.item" :key="key">{{ key }}:  {{ value }}</li>
								  </ul>
								</b-card>
							  </template>
							  <template #cell(actions)="row">
								<b-button @click="info(row.item, row.index, $event.target)" size="sm" variant="success" class="mr-1">
								  <i  class="flaticon-eye"></i>
								</b-button>
								<!--<b-button size="sm" @click="row.toggleDetails">
								  {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
								</b-button>-->
							  </template>
							</b-table>
							<b-col sm="5" md="6" class="my-1">
						 
							 </b-col>
							<b-col sm="7" md="6" class="my-1">
								<b-pagination
								  v-model="currentPage"
								  :total-rows="totalRows"
								  :per-page="perPage"
								  align="fill"
								  size="sm"
								  class="my-0"
								></b-pagination>
							  </b-col>
							<!-- Info modal -->
							<b-modal :id="infoModal.id" :title="infoModal.title" hide-footer size="xl" @hide="resetInfoModal">
								<div id="TicketElement">
									  <b>{{$t('ORDER.MSG_MEANS_PAYMENT')}}</b>
									  <span v-if="infoModal.content.bank_transfer == 'true'">{{$t('ORDER.MSG_BANK_TRANSFER')}}</span>
									  <span v-if="infoModal.content.cash == 'true'">{{$t('ORDER.MSG_CASH')}}</span>
									  <span v-if="infoModal.content.digipostbank == 'true'">{{$t('ORDER.MSG_DIGIPOSTBANK')}}</span>
									  <span v-if="infoModal.content.postal_order == 'true'">{{$t('ORDER.MSG_POSTAL_ORDER')}}</span>
									  <span v-if="infoModal.content.ticket_restaurant == 'true'">{{$t('ORDER.MSG_TICKET_RESTAURANT')}}</span>
									  <span v-if="infoModal.content.clictopay == 'true'">{{$t('ORDER.MSG_CLICTOPAY')}}</span>
									  <br>
									  <b>{{$t('ORDER.MSG_ORDER')}}</b>
									  <span >{{infoModal.content.methode}}<br></span>
									  <span v-if="infoModal.content.table_number != ''"><b>{{$t('ORDER.MSG_TABLE')}}</b><br> {{infoModal.content.table_number}}<br></span>
									  <b>{{$t('ORDER.MSG_USER')}}</b><br>
									  <span v-if="infoModal.content.name != ''">{{infoModal.content.name}}<br></span>
									  <span v-if="infoModal.content.phone != ''">{{infoModal.content.phone}}<br></span>
									  <span v-if="infoModal.content.mail != ''">{{infoModal.content.mail}}<br></span>
									  <span v-if="infoModal.content.adresse != ''">{{infoModal.content.adresse}}<br></span>
									  <b v-if="infoModal.content.note != ''">Informations complémentaires :</b>
									  <span v-if="infoModal.content.note != ''">{{infoModal.content.note}}<br></span>
									  <br>
									  <br>
										<table style="width: 100%;" class="">
											<tbody>
												<tr v-for="(value, key) in infoModal.content.product" :key="key">
													<td style="width: 20%;text-align: center;">{{value.qte}}</td>
													<td style="width: 50%;"><b>{{value.name}}</b></td>
													<td style="width: 30%;text-align: end;">{{ CurrencyFormat(value.price_qte) }}</td>
												</tr>
											</tbody>
										</table>
										<br>
										<table style="width: 100%;" class="table ">
											<tbody>
												<tr v-if="infoModal.content.delivery_fees != ''">
													<td style="width: 70%;text-align: end;">{{$t('ORDER.MSG_SHIPPING_COST')}}</td>
													<td style="width: 30%;text-align: end;">{{ CurrencyFormat(infoModal.content.delivery_fees) }}</td>
												</tr>
												
												<tr v-if="infoModal.content.tax != ''">
													<td style="width: 70%;text-align: end;">{{$t('ORDER.MSG_Tax')}}</td>
													<td style="width: 30%;text-align: end;">{{ CurrencyFormat(infoModal.content.tax) }}</td>
												</tr>
												<tr v-if="infoModal.content.tax != ''">
													<td style="width: 70%;text-align: end;">{{$t('ORDER.MSG_TOTAL')}}</td>
													<td style="width: 30%;text-align: end;"><b>{{ CurrencyFormat(infoModal.content.total_price_final) }}</b></td>
												</tr>
											</tbody>
										</table>
								</div>
								<div class="row">
									<div class="col-5" style="text-align: end;">
										
									</div>
									<div class="col-5" style="text-align: end;">
										
									</div>
									<div @click="PrintTicket(infoModal.title)" class="col-2" style="text-align: end;">
										<b-button size="sm"  class="mr-1" >
										  <i  class="flaticon-technology"></i>
										</b-button>
									</div>
								</div>
								<hr>
								<div class="row">
									<div class="col-12">
										<span>{{$t('ORDER.MSG_STATUS')}}</span>
										<select  class="form-control form-control-lg form-control-solid" ref="ValueOrderStatus">
											<option v-for="(value, name) in OrderStatus" :key="name" :value="name" :selected="(infoModal.content.status ? infoModal.content.status.toUpperCase() : infoModal.content.status) == name ? true : false">{{value.text}}</option>
										</select>
										<br>
									</div>
									
									<div class="col-12">
										<span>{{$t('ORDER.MSG_PAYMENT')}}</span>
										<select  class="form-control form-control-lg form-control-solid" ref="ValueOrderPayment">
											<option v-for="(value, name) in OrderPayment" :key="name" :value="name" :selected="(infoModal.content.payment_status ? infoModal.content.payment_status.toUpperCase() : infoModal.content.payment_status) == name ? true : false">{{value.text}}</option>
										</select>
										<br>
									</div>
									
									<div class="col-12">
										<span>{{$t('ORDER.MSG_NOTE')}}</span>
										<textarea class="form-control form-control-lg form-control-solid" ref="NoteOrder"> </textarea>
										<br>
									</div>
									
									<div class="col-12">
										<div class="form-group row">
											
											<div class="col-lg-2 col-xl-2 col-2">
												<span class="switch switch-sm">
													<label>
														<input type="checkbox" ref="NotifUser"  name="email_notification_660"/>
														<span></span>
													</label>
												</span>
											</div>
											<label class="col-xl-10 col-lg-10 col-10 col-form-label text-left">{{$t('ORDER.MSG_SEND_NOTIF_TO_USER')}}</label>
										</div>
										<br>
									</div>
									
									<div  class="col-12" style="text-align: center;">
										<b-button size="lg"  class="mr-1" variant="success" @click="UpdateOrder(infoModal.content.RowKey,infoModal.content.phone)">
											{{$t('ORDER.MSG_SAVE')}}
										</b-button>
									</div>
								</div>
								<h5 style="padding: 1.75rem 1.75rem 1.5rem 0rem; border-bottom: 1px solid rgb(238, 238, 238);border-top: 1px solid #eee;margin-top: 20px;margin-bottom: 12px;">Historique de commande</h5>
								
								<div class="row" if="infoModal.content.historical.length != 0">
									<div class="col-12">
										
										<div class="mb-3" v-for="(I,key) in infoModal.content.historical" :key="I">
											<div class="d-flex align-items-center flex-grow-1">
												<label class="flex-shrink-0 mr-4" >
													<a href="#" style="font-size:10px;text-align: center;" class="text-dark-75 font-weight-bold text-hover-primary mb-1">{{ moment(parseFloat(key)).format("DD/MM/YYYY")}}<br>{{ moment(parseFloat(key)).format("HH:mm:ss")}}</a>
												</label> 
												<div style="width: 100%;">
													<b class="badgeStatus" :style="{background:OrderStatus[I.status.toUpperCase()].color}" v-if="I.status != '' && I.status != null && I.status != 'null'">{{OrderStatus[I.status.toUpperCase()].text}}</b>
													&nbsp;<b class="badgeStatus" v-if="I.payment != '' && I.payment != null && I.payment != 'null'" :style="{background:OrderPayment[I.payment].color}" >{{OrderPayment[I.payment].text}}</b>
													<div class="d-flex flex-column align-items-cente py-2 w-100">
														<span class="text-muted font-weight-bold">{{$t('ORDER.MSG_NOTE')}} {{I.note}}</span>
													</div>
													
												</div>
											</div>
											<hr>
										</div>
									</div>
								</div>
								
							</b-modal>
						  </b-container>
					</template>
				</div>
			</div>
		</div>
	</div>
	<!--begin::Dashboard-->
    
    <!--end::Dashboard-->
  </div>
</template>
<style>
.badgeStatus{
	color: #fff;
	padding: 1px 6px 1px 6px;
	border-radius: 7px;
	font-size: 11px;
}
</style>
<script>
/*
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
*/
import axios from "axios";
 
import { mapGetters } from "vuex";

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
//import { Settings } from 'luxon'
//Settings.defaultLocale = 'fr'
//Settings.defaultZone = "America/Bogus"
//console.log(Settings);
//Settings.DateTime.fromJSDate(new Date())
//Datetime.set({ outputCalendar: 'beng', zone: 'utc' })
import moment from 'moment';

moment.locale(sessionStorage.getItem('language'));
moment.updateLocale(sessionStorage.getItem('language'), {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});
export default {
  name: "dashboard",
  components: {
	datetime: Datetime,
	
	/* AdvancedTableWidget2,
    AdvancedTableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13*/
  },
   data() {
    return {
		imgUrl:"",
		dateStart:"",
		dateEnd:"",
		newDate :"",
		date :"",
		moment:moment,
		fields: [
				
				{ key: 'type', label: 'Type', sortable: false, sortDirection: 'asc' },
				{ key: 'methode', label: 'Méthode', sortable: true, sortDirection: 'asc' },
				{ key: 'status', label: 'Statut', sortable: true, sortDirection: 'asc' },
				{ key: 'date_order', label: 'Date', sortable: true, sortDirection: 'asc' },
				{ key: 'RowKey', label: 'Num', sortable: true},
				{ key: 'price_total', label: 'Prix Total', sortable: true},
				{ key: 'payment_methods', label: 'Méthode', sortable: true},
				{ key: 'name', label: 'Nom', sortable: true},
				{ key: 'adresse', label: 'adresse', sortable: false},
				{ key: 'phone', label: 'Téléphone', sortable: false},
				//{ key: 'mail', label: 'E-mail', sortable: false},
				{key: 'actions', label: ''}
		],
        items: [],
        CalculOrderStatus: {},
        OrderStatus:{	
						PROCESS:{text:"Commandes en cours",color:"#ED1C24",description:""},
						CREATE:{text:"En attente",color:"#FFA800",description:"%shop% vous remercie pour votre confiance ! La commande %num% est En attente de validation par nos équipes. \n %Note%"},
						//PROCESSING_ORDER:{text:"En cours",color:"#6993FF",description:"La commande %num% est En cours."},
						CONFIRM:{text:"Confirmée",color:"#E4E6EF",description:"La commande %num% est Confirmée. \n %Note%"},
						BEING_PROCESSED:{text:"En cours de traitement",color:"#3699FF",description:"La commande %num% est En cours de traitement. \n %Note%"},
						IN_DELIVERING:{text:"Expédiée",color:"#6993FF",description:"La commande %num% est En cours de livraison. \n %Note%"},
						ORDER_COMPLETED:{text:"Finalisée",color:"#1BC5BD",description:"La commande %num% est Finalisée. \n %Note%"},
						DELIVERY:{text:"Réceptionnée",color:"#181C32",description:"La commande %num% est Livrée.  \n %Note%"},
						CANCELED :{text:"Annulée",color:"#F64E60",description:"La commande %num% est Annulée.  \n %Note%"}
					},
		OrderPayment:{
						NULL:{text:"-",color:"#000000"},
						PAYMENT_PENDING:{text:"Paiement En attente",color:"#FF6600"},
						PAYMENT_COMPLETED:{text:"Payé",color:"#85BB65"},
						PAYMENT_INVALID:{text:"Paiement Echoué",color:"#ED1C24"}
					},
		ModeDelivery:{
						delivery:{text:"Livraison",color:"#6993FF"},
						self_pickups:{text:"Collecte",color:"#181C32"},
						in_resto:{text:"Au restaurant",color:"#E4E6EF"}
					},
		ModePassOrder:{
						messenger:{text:"Messenger",icon:"mdi-facebook"},
						whatsapp:{text:"WhatsApp",icon:"mdi-whatsapp"},
						sms:{text:"SMS",icon:"mdi-phone"},
						smtp:{text:"E-mail",icon:"mdi-email"}
					},
		totalRows :  0,
        tableVariants: [
          'primary',
          'secondary',
          'info',
          'danger',
          'warning',
          'success',
          'light',
          'dark'
        ],
        striped: false,
        bordered: false,
        borderless: false,
        outlined: false,
        small: false,
        hover: false,
        dark: false,
        fixed: false,
        footClone: false,
        headVariant: null,
        tableVariant: '',
        noCollapse: false,
        currentPage: 1,
        perPage: 10,
        pageOptions: [ 10, 20, 30, { value: 1000, text: "Tout" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
			infoModal: {
			  id: 'info-modal',
			  title: '',
			  content: ''
			},
		}
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
			.filter(f => f.sortable)
			.map(f => {
				return { text: f.label, value: f.key }
			})
		},
		
		...mapGetters(["currentUserAccountInfo"]),
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["numberFormat"])
    },
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		
		this.imgUrl = process.env.VUE_APP_APP_API+'/klik/product/img/';
		this.dateStart = moment().startOf('day').toISOString();
		this.dateEnd = moment().endOf('day').toISOString();
		this.GetOrder();
		//this.dateStart = new Date().toISOString();
		this.OrderStatus = {	
						PROCESS:{text:this.$t('ORDER.MSG_ORDER_CURRENT_ORDERS'),color:"#ED1C24",description:this.$t('ORDER.MSG_ORDER_CURRENT_ORDERS_DESCRIPTION')},
						CREATE:{text:this.$t('ORDER.MSG_ORDER_WAITING'),color:"#FFA800",description:this.$t('ORDER.MSG_ORDER_WAITING_DESCRIPTION')},
						//PROCESSING_ORDER:{text:"En cours",color:"#6993FF",description:"La commande %num% est En cours."},
						CONFIRM:{text:this.$t('ORDER.MSG_ORDER_CONFIRMED'),color:"#E4E6EF",description:this.$t('ORDER.MSG_ORDER_CONFIRMED_DESCRIPTION')},
						BEING_PROCESSED:{text:this.$t('ORDER.MSG_ORDER_BEING_PROCESSED'),color:"#3699FF",description:this.$t('ORDER.MSG_ORDER_BEING_PROCESSED_DESCRIPTION')},
						IN_DELIVERING:{text:this.$t('ORDER.MSG_ORDER_SHIPPED'),color:"#6993FF",description:this.$t('ORDER.MSG_ORDER_SHIPPED_DESCRIPTION')},
						ORDER_COMPLETED:{text:this.$t('ORDER.MSG_ORDER_FINALIZED'),color:"#1BC5BD",description:this.$t('ORDER.MSG_ORDER_FINALIZED_DESCRIPTION')},
						DELIVERY:{text:this.$t('ORDER.MSG_ORDER_RECEIVED'),color:"#181C32",description:this.$t('ORDER.MSG_ORDER_RECEIVED_DESCRIPTION')},
						CANCELED :{text:this.$t('ORDER.MSG_ORDER_CANCELED'),color:"#F64E60",description:this.$t('ORDER.MSG_ORDER_CANCELED_DESCRIPTION')}
					};
		this.OrderPayment = {
						NULL:{text:"-",color:"#000000"},
						PAYMENT_PENDING:{text:this.$t('ORDER.MSG_ORDER_PAYMENT_PENDING'),color:"#FF6600"},
						PAYMENT_COMPLETED:{text:this.$t('ORDER.MSG_ORDER_PAID'),color:"#85BB65"},
						PAYMENT_INVALID:{text:this.$t('ORDER.MSG_ORDER_FAILED_PAYMENT'),color:"#ED1C24"}
					};
		this.ModeDelivery = {
						delivery:{text:this.$t('MSG_DELIVERY'),color:"#6993FF"},
						self_pickups:{text:this.$t('MSG_SELF_PICKUPS'),color:"#181C32"},
						in_resto:{text:this.$t('MSG_IN_RESTO'),color:"#E4E6EF"}
					};
		this.ModePassOrder = {
						messenger:{text:"Messenger",icon:"mdi-facebook"},
						whatsapp:{text:"WhatsApp",icon:"mdi-whatsapp"},
						sms:{text:"SMS",icon:"mdi-phone"},
						smtp:{text:"E-mail",icon:"mdi-email"}
					};
	},
	methods: {
		info(item, index, button) {
			this.infoModal.title = this.$t('ORDER.MSG_ORDER_NUMBER')+item.RowKey
			this.infoModal.content = item;//JSON.stringify(item, null, 2)
			this.$root.$emit('bv::show::modal', this.infoModal.id, button);
		},
		CurrencyFormat(number) {
			return this.numberFormat(number);
		},
		resetInfoModal() {
			this.infoModal.title = ''
			this.infoModal.content = ''
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		PrintTicket(num) {
			var mywindow = window.open('', 'PRINT', 'height=400,width=600');

			mywindow.document.write('<html><head><title>Doken</title>');
			mywindow.document.write('</head><body >');
			mywindow.document.write('<h1>' + num  + '</h1>');
			mywindow.document.write(document.getElementById("TicketElement").innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();
		},
		ShowLoading(Action) {
			var body = document.body;
			Action ? body.classList.add("page-loading") : body.classList.remove("page-loading");
		},
		GetOrder() {
			this.items = [];
			this.totalRows = 0;
			var ds = new Date(this.dateStart).getTime();
			var de = new Date(this.dateEnd).getTime();
			
			ds = moment(ds).startOf('day').valueOf();
			de = moment(de).endOf('day').valueOf();
			
			if(ds >= de){
				var dd = ds;
				ds = de ;
				de = dd ;
				this.dateStart = moment(ds).startOf('day').toISOString();
				this.dateEnd = moment(de).endOf('day').toISOString();
			}
			this.ShowLoading(true);
			var data = {ds:ds.toString(),de:de.toString()};
			axios.post("/klik/customer/order/get",data).then((response) => {
					this.CalculOrderStatus = {};
					Object.keys(response.data.Order).forEach((i)=>{
						if(response.data.Order[i].order.methode != ""){
							if(!this.CalculOrderStatus[response.data.Order[i].order.status.toUpperCase()]){
								this.CalculOrderStatus[response.data.Order[i].order.status.toUpperCase()] = 0;
							}
							this.CalculOrderStatus[response.data.Order[i].order.status.toUpperCase()] = this.CalculOrderStatus[response.data.Order[i].order.status.toUpperCase()] + 1 ;//parseFloat(response.data.Order[i].order.price_total);
							console.log(this.CalculOrderStatus);
							response.data.Order[i].order.product = response.data.Order[i].product;
							response.data.Order[i].order.historical = (response.data.Order[i].order.historical && response.data.Order[i].order.historical != null && response.data.Order[i].order.historical != "null") ? JSON.parse(response.data.Order[i].order.historical) : {};
							this.items.push(response.data.Order[i].order)
						}
					});
					this.totalRows = this.items.length;
					this.ShowLoading(false);
				}).catch((errors) => {    
					this.ShowLoading(false);
					localStorage.clear(errors);				
					this.$router.push('/login');
				});
		},
		UpdateOrder(RowKey,number_W) {
			var ValueOrderStatus = this.$refs.ValueOrderStatus.value;
			var ValueOrderPayment = this.$refs.ValueOrderPayment.value;
			var NoteOrder = this.$refs.NoteOrder.value;
			var Notif = this.$refs.NotifUser.checked;
			var data = {Key:RowKey,status:ValueOrderStatus,payment:ValueOrderPayment,note:NoteOrder,notif:Notif};
			axios.post("/klik/customer/order/update",data).then((response) => {
				var msg = this.OrderStatus[ValueOrderStatus].description;
				msg = msg.replace('%shop%', this.currentUserPersonalInfo.name);
				msg = msg.replace('%num%', RowKey);
				if(NoteOrder != ""){
					msg = msg.replace('%Note%', "Note : "+NoteOrder);
				}else{
					msg = msg.replace('%Note%', "");
				}
				
				if(response.data.notif == "whatsapp"){
					window.open("https://wa.me/" + number_W + "?text=" + encodeURI(msg) , '_blank'); 
				}
				this.GetOrder(response);
				this.$root.$emit('bv::hide::modal', this.infoModal.id, false);
				
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},

	}
};
</script>
